/* eslint-disable */
import { base, baseUpload, baseXlsx } from '@/utils/api'

const ENDPOINT = '/brasindice-updates'

export default {
    getItems(page, clinicId, brasindicePropId, query) {
        const config = {
            params: { page, clinicId, query}
        };
        return base.get(`${ENDPOINT}/${brasindicePropId}/brasindice`, config);
    },
    getIds(brasindicePropId) {
        const config = {
            params: { brasindicePropId }
        };
        return base.get(`${ENDPOINT}/batch/ids`, config);
    },
    create(props) {
        return base.post(`${ENDPOINT}`, props);
    },
    update(id, props) {
        return base.put(`${ENDPOINT}/${id}`, props);
    },
    delete(id) {
        return base.delete(`${ENDPOINT}/${id}`);
    },
    deleteBatch(ids) {
        return base.put(`${ENDPOINT}/batch/delete`, { ids });
    },
}
<template>
    <div>
        <div class="table-details-content">
            <PageHeader>
                <template v-slot:header>
                    <b-skeleton v-if="!brasindiceProp" animation="fade" width="300px"></b-skeleton>
                    <div v-else>
                        <div>{{ brasindiceProp.item }}</div>
                        <div class="apres">{{ brasindiceProp.apres }}</div>
                    </div>
                </template>
                <template v-slot:actions>
                    <div class="actions">
                        <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getBrasindiceUpdates()"/>
                        <div 
                            v-if="selecteds.length" 
                            class="batch-delete-wrapper" 
                            v-b-tooltip.hover title="Excluir items"
                            @click="deleteSelecteds"
                        >
                            <b-badge class="badge" variant="danger">{{ selecteds.length }}</b-badge>
                            <Delete class="icon" />
                        </div>
                    </div>
                </template>
            </PageHeader>
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-group>
                        <b-form-input 
                            v-model="query" 
                            placeholder="Digite uma edição ou atualização para pesquisar..."
                            @input="searchDebounce"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <TableBrasindiceUpdates 
                :tableId="tableId"
                :brasindiceUpdates="brasindiceUpdates"
                :selecteds="selecteds"
                @delete="deleteItem"
                @handlerMasterSelect="handlerMasterSelect"
                @handlerSelect="handlerSelect"
            />
            <div class="pagination-position">
                <b-pagination
                    v-model="page"
                    :total-rows="count"
                    :per-page="limit"
                    first-number
                    last-number
                    size="sm"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import api from '@matMed/brasindice-update.api'
import * as CONSTANTS from '@tables/utils/constants'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    props: {
        multiPages: Boolean,
        table: Object,
        brasindiceProp: Object,
        brasindicePropId: String
    },
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),   
        Delete: () => import('@/assets/icons/delete.svg'), 
        PageHeader: () => import('@/components/General/PageHeader'),
        TableBrasindiceUpdates: () => import('@matMed/layouts/TableBrasindiceUpdates'),
        // MatMedModal: () => import('@matMed/modals/MatMedModal'),
        // ImportItemProgress: () => import('@items/components/ImportItemProgress'),
    },
    mounted() {
        this.searchDebounce = debounce(this.getBrasindiceUpdates, 300);
        this.getBrasindiceUpdates()
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            CONSTANTS,
            loading: false,
            tableId: this.$route.params.id,
            type: null,
            item: null,
            brasindiceUpdates: [],
            selecteds: [],
            query: null,
            page: 0,
            count: 0,
            limit: 0,
            searchDebounce: Function
        }
    },
    methods: {
        getBrasindiceUpdates() {
            this.loading = true
            api.getItems(this.page, this.clinic.id, this.brasindicePropId, this.query)
            .then(({ data }) => {
                this.brasindiceUpdates = data.data
                this.count = data.total
                this.limit = data.per_page
            })
            .catch(error => this.$toast.error(error.message))
            .finally(() => (this.loading = false));
        },
        onSaved(item) {
            const index = this.brasindiceUpdates.findIndex(({ id }) => id === item.id)
            index >= 0 ?
                this.brasindiceUpdates.splice(index, 1, item) :
                this.brasindiceUpdates.push(item)
        },
        async deleteItem(item) {
            try {
                await api.delete(item.id)
                this.$toast.success('Item excluído com sucesso')
                const index = this.brasindiceUpdates.findIndex(({ id }) => id === item.id)
                this.brasindiceUpdates.splice(index, 1)
                this.selecteds = [];
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                
            }
        },
        deleteSelecteds() {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir os <span>${this.selecteds.length} itens</span> selecionados?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.deleteBatch(this.selecteds)
                        this.$toast.success('Itens excluídos com sucesso')
                        this.selecteds.forEach(id => {
                            const index = this.brasindiceUpdates.findIndex(item => item.id === id)
                            this.brasindiceUpdates.splice(index, 1)
                            this.page !== 1 && (this.page = 1)
                        })
                        this.selecteds = []
                    } catch (error) {
                        this.$toast.error(error.message)
                    }
                }
            })
        },
        async handlerMasterSelect(checked) {
            if (!checked) {
                this.selecteds = []
            } else if (!this.multiPages) {
                this.selecteds = this.brasindiceUpdates.map(({ id }) => id)
            } else {
                this.loading = true
                try {
                    const { data } = await api.getIds(this.tableId)
                    this.selecteds = data
                } catch (error) {
                    this.$toast.error(error.message)
                } finally {
                    this.loading = false
                }
            }
        },
        handlerSelect(item) {
            const index = this.selecteds.findIndex(id => id === item.id)
            index >= 0 ?
                this.selecteds.splice(index, 1) :
                this.selecteds.push(item.id)
        }
    },
    watch: {
        page() {
            this.getBrasindiceUpdates()
        }
    }
}
</script>
<style lang="scss" scoped>
.table-details-content {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    padding: 1rem;
}
.apres {
    font-size: 0.8rem;
    color: var(--neutral-400);
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
.batch-delete-wrapper {
    position: relative;
    cursor: pointer;

    .badge {
        position: absolute;
        right: -6px;
        top: -4px;
    }
}
</style>